<template>
  <div>
    <div class="is-flex is-justify-content-flex-end mb-2">
      <div>
        <b-button
          type="is-primary"
          size="is-small"
          class="mr-3"
          :disabled="isTableLoading"
          @click="analyzeModule()"
        >
          Refresh
        </b-button>
      </div>
    </div>
    <b-table
      :data="tableData"
      :striped="true"
      :bordered="true"
      :hoverable="true"
      :paginated="true"
      :per-page="20"
      :total="tableData.length"
      :sticky-header="true"
      :loading="isTableLoading"
      pagination-size="is-small"
      default-sort-direction="asc"
    >
      <b-table-column
        field="affected_item"
        label="Node"
        sortable
        searchable
      >
        <template #default="{ row }">
          {{ row.affected_item }}
        </template>
      </b-table-column>

      <b-table-column
        field="description"
        label="Issue"
        sortable
        searchable
      >
        <template #default="{ row }">
          {{ row.description }}
        </template>
      </b-table-column>
    </b-table>
  </div>
</template>

<script >
import { onMounted, ref } from '@vue/composition-api';
import { useRoute } from '@/hooks/vueRouter';
import { aiAnalyseModuleService } from '@/services/nuclicore-service/aiCopilotRequests';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const route = useRoute();
  const tableData = ref([]);
  const isTableLoading = ref(false);
  const analyzeModule = async () => {
    try {
      isTableLoading.value = true;
      const {
        appId,
        moduleId
      } = route.params;
      const hostParts = window.location.hostname.split('.');
      const subdomain = hostParts.length > 2 ? hostParts[0] : null;
      if (subdomain) {
        const {
          data
        } = await aiAnalyseModuleService({
          appId,
          moduleId,
          server: subdomain
        });
        tableData.value = data.criticalIssues;
      }
    } catch (err) {
      console.error(err);
    } finally {
      isTableLoading.value = false;
    }
  };
  onMounted(() => {
    analyzeModule();
  });
  return {
    tableData,
    isTableLoading,
    analyzeModule
  };
};
export default __sfc_main;
</script>
