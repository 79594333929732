import { createHttpServiceConfig } from '@/services/httpFactories';
import { AI_COPILOT_RESOURCE_PATH, NUCLICORE_PLATFORM_API_ENDPONT } from './nuclicoreServiceConstants';

const aiCopilotServiceConfig = createHttpServiceConfig(NUCLICORE_PLATFORM_API_ENDPONT, AI_COPILOT_RESOURCE_PATH);

/**
 * @param {import('./nuclicoreServiceTypes').IAiCopilotServiceParams} params 
 */
export const aiAnalyseModuleService = (params) => aiCopilotServiceConfig.http({
    method: 'GET',
    params
});
