<template>
  <div>
    <AiModuleAnalyzer />
  </div>
</template>

<script >
import AiModuleAnalyzer from '@/modules/builder/components/ai-copilot/AiModuleAnalyzer.vue';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  return {};
};
__sfc_main.components = Object.assign({
  AiModuleAnalyzer
}, __sfc_main.components);
export default __sfc_main;
</script>
